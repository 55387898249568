.account-body {
    padding: 20px;
    background-attachment: fixed;
    background-position-x: center;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
    overflow-x: hidden;
}

.account-title {
    text-shadow: -1px 1px 3px white;
    color: #1d39c4;
}

.form-submit {
    width: 100%;
}