/*.ant-la*/
.ant-pro-top-nav-header {
    height: 65px;
}
.ant-pro-global-header {
    height: 65px;
}

.ant-pro-page-container {
    /*background-image: ;*/
    /*top: 200px;*/
    /*height: calc(100vh - 65px);*/
}

.ant-pro-top-nav-header-logo h1 {
    font-size: 35px;
    font-weight: bold;
}

.ant-pro-global-header-logo a img{
    height: 65px;
    width: 65px;
}

/*.ant-pro-grid-content {*/
/*    background-image: url("./images/dashboard_background.jpg");*/
/*    background-repeat: no-repeat;*/
/*    background-size: contain;*/
/*    background-attachment: fixed;*/
/*    background-position-x: center;*/
/*    background-position-y: bottom;*/
/*}*/

.ant-layout-content {
    background-image: url("./images/dashboard_background.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-position-x: center;
    background-position-y: bottom;
}

/*.ant-pro-page-container {*/
/*    background-color: red;*/
/*}*/


.ant-pro-grid-content-children {
    /*padding: 20px;*/
}

/*.ant-layout-header {*/
/*    height: 100px;*/
/*}*/