.app_container {
    width: 100%;
    height: 100vh;
    background-size: 100%;
    background-repeat: no-repeat;
}

.rtl {
    direction: rtl;
}

.ltr {
    direction: ltr;
}

.center {
    text-align: center;
}

.mirror {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
}

.form {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5);
    max-width: 500px;
    /*text-align: center;*/
    /*margin: 100px;*/
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto 50px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
