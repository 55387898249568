html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'IRANSans', 'Vazir', sans-serif;
}

.noscript-enableJS {
    padding-right: 3px;
    padding-left: 3px;
}

.page-loading-warp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20%;
}